<template>
  <v-scale-screen width="1920" height="1080" :fullScreen="true">
    <div id="monitoringRight">
      <div class="right_header"></div>
      <div class="row-style">
        <div class="right_div1">
<!--          <el-date-picker class="z-index" :clearable="false" v-model="selectTimeThree" @change="timeChangeThree()"-->
<!--                          placeholder="选择日"-->
<!--                          style="width:150px" type="date" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd"-->
<!--                          popper-class="echarts-picker"></el-date-picker>-->
<!--          <el-date-picker style="width: 122px" class="z-index" v-model="monthlyDay" type="date" placeholder="日"-->
<!--                          value-format="yyyy-MM-dd" :clearable="false" popper-class="echarts-picker"-->
<!--                          @change="dayChange"></el-date-picker>-->
<!--          <el-date-picker v-model="monthlyMonth" style="right: 182px; width: 106px" type="month" value-format="yyyy-MM"-->
<!--                          placeholder="月" :clearable="false" popper-class="echarts-picker" @change="monthChange">-->
<!--          </el-date-picker>-->
<!--          <el-date-picker v-model="monthlyYear" style="right: 298px; width: 86px" value-format="yyyy" :clearable="false"-->
<!--                          type="year" placeholder="年" popper-class="echarts-picker" @change="yearChange">-->
<!--          </el-date-picker>-->
          <div id="chart1"></div>
        </div>
        <div class="right_div2">
          <el-date-picker class="z-index" :clearable="false" v-model="selectTimeTwo" @change="timeChangeTwo()"
                          placeholder="选择日"
                          style="width:150px" type="year" value-format="yyyy" format="yyyy"
                          popper-class="echarts-picker"></el-date-picker>
          <div id="chart2"></div>
        </div>
      </div>
      <div class="row-style">
        <div class="right_div3">
<!--          <el-date-picker class="z-index" :clearable="false" v-model="selectTimeOne" @change="timeChangeOne()"-->
<!--                          :picker-options="expireTimeOPtion" placeholder="选择日"-->
<!--                          style="width:150px" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd"-->
<!--                          popper-class="echarts-picker"></el-date-picker>-->
          <!--          <el-date-picker style="width: 122px" class="z-index" v-model="monthlyDay3" type="date" placeholder="日"-->
          <!--            value-format="yyyy-MM-dd" :clearable="false" popper-class="echarts-picker"-->
          <!--            @change="dayChange3"></el-date-picker>-->
          <!--          <el-date-picker v-model="monthlyMonth3" style="right: 182px; width: 106px" type="month" value-format="yyyy-MM"-->
          <!--            placeholder="月" :clearable="false" popper-class="echarts-picker" @change="monthChange3">-->
          <!--          </el-date-picker>-->
          <!--          <el-date-picker v-model="monthlyYear3" style="right: 298px; width: 86px" value-format="yyyy" :clearable="false"-->
          <!--            type="year" placeholder="年" popper-class="echarts-picker" @change="yearChange3">-->
          <!--          </el-date-picker>-->
          <div id="chart3"></div>
        </div>
        <div class="right_div4">
          <!--          <el-date-picker style="width: 122px" class="z-index" v-model="monthlyDay4" type="date" placeholder="日"-->
          <!--            value-format="yyyy-MM-dd" :clearable="false" popper-class="echarts-picker"-->
          <!--            @change="dayChange4"></el-date-picker>-->
          <!--          <el-date-picker v-model="monthlyMonth4" style="right: 182px; width: 106px" type="month" value-format="yyyy-MM"-->
          <!--            placeholder="月" :clearable="false" popper-class="echarts-picker" @change="monthChange4">-->
          <!--          </el-date-picker>-->
          <el-date-picker v-model="monthlyYear4" style="right: 298px; width: 86px" value-format="yyyy"
                          :clearable="false"
                          type="year" placeholder="年" popper-class="echarts-picker" @change="yearChange4">
          </el-date-picker>
          <div id="chart4"></div>
        </div>
      </div>
      <div class="row-style">
        <div class="right_div5">
          <div id="chart5"></div>
        </div>
        <div class="right_div6">
          <div class="tabNavBox">
            <div :class="tab == index? 'navClick':'nav'" v-for="(item,index) in tabNavList" :key="index"
                 @click="clickNav(index)">{{ item.label }}
            </div>
          </div>
          <!--          <el-select-->
          <!--            v-model="capacity"-->
          <!--            style="right: 184px"-->
          <!--            :popper-append-to-body="false"-->
          <!--            placeholder="容量"-->
          <!--            @change="capacityChange"-->
          <!--          >-->
          <!--            <el-option-->
          <!--              v-for="item in capacityOptions"-->
          <!--              :key="item.id"-->
          <!--              :label="item.grossRatedCapacity"-->
          <!--              :value="item.grossRatedCapacity"-->
          <!--            >-->
          <!--            </el-option>-->
          <!--          </el-select>-->
          <!--          <el-select @change="typeChange" v-model="type" placeholder="类型" style="width: 122px" filterable-->
          <!--            :popper-append-to-body="false">-->
          <!--            <el-option v-for="item in typeOptions" :key="item.id" :label="item.transformerModel"-->
          <!--              :value="item.transformerModel">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
          <div id="chart6"></div>
        </div>
      </div>
      <!-- <div class="monitoringFooter"></div> -->
    </div>
    <div class="left_nav" @click="jump()"></div>
  </v-scale-screen>
</template>
<script>
import * as echarts from "echarts";
import {monitorApi} from "@/api/monitor.js";
import xDate from "../../utils/ecahrtXdate.js";
import moment from "moment";

export default {
  data() {
    return {
      tab: '1',
      tabNavList: [{value: '0', label: '容量'}, {value: '0', label: '类型'}],
      regionOptions: [
        {label: "地区", value: 1},
        {label: "分中心", value: 2},
      ],
      capacityOptions: [],
      typeOptions: [],
      carbonRegion: 1,
      electricityRegion: 1,
      produceRegion: 1,
      powerRegion: 1,
      capacity: "",
      type: "",
      produceMonth: null,
      produceYear: null,
      electricityYear: null,
      electricityMonth: null,
      monthlyDay: null,
      monthlyMonth: null,
      monthlyYear: null,
      monthlyDay2: null,
      monthlyMonth2: null,
      monthlyYear2: null,
      monthlyDay3: null,
      monthlyMonth3: null,
      monthlyYear3: null,
      monthlyDay4: null,
      monthlyMonth4: null,
      monthlyYear4: null,
      requestType: 0,
      staTime: '',
      endTime: '',
      selectTimeOne: '',
      selectTimeTwo: '',
      selectTimeThree: '',
      expireTimeOPtion: {
        disabledDate(time) {
          let date = new Date();
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
    };
  },
  //懒得封装方法，直接写生命周期里了，习惯代码规范的可以自行封装放入方法
  created() {
    this.monthlyYear4 = moment().format('YYYY');
    //昨日日期选择
    // this.selectTimeOne = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.selectTimeOne = moment().format('YYYY-MM-DD')
    this.selectTimeTwo = moment().format('YYYY');
    this.selectTimeThree = moment().format('YYYY-MM-DD HH:mm:ss');
    const date = new Date(); // 获取当前时间
    let year = date.getFullYear(); // 获取当前年份
    let month = date.getMonth() + 1; // 获取当前月份
    let day = date.getDate() + 1; // 获取当前月份
    this.produceYear = `${year}`;
    this.monthlyYear = `${year}`;
    this.electricityYear = `${year}`;
    // this.monthlyMonth = `${year}-${month}`;
    // this.monthlyDay=`${year}-${month}-${day}`
    if (month < 10) {
      this.produceMonth = `${year}-0${month}`;
      this.electricityMonth = `${year}-0${month}`;
    } else {
      this.produceMonth = `${year}-${month}`;
      this.electricityMonth = `${year}-${month}`;
    }
    this.queryType();
    this.getThisMonth('model')
    this.getMonthOnMonth()
    this.getSelectPowergListByDay()
    this.getSelectPowergChainByYear()
    this.getGetrealTimePowerStatistics()
    this.getGetCarbonEmissionStatistics()


  },
  mounted() {
    // this.queryCapacity();
    this.init();
  },
  methods: {
    //当月能耗分析情况统计
    //按钮切换
    clickNav(index) {
      this.tab = index
      if (index == '0') {
        this.getThisMonth('capacity')
      } else if (index == '1') {
        this.getThisMonth('model')
      }
    },
    //当月能耗分析情况统计数据
    getThisMonth(data) {
      let param = {
        groupType: data
      }
      monitorApi.thisMonth(param).then((res) => {
        if (res.code === 200) {
          this.setchart6(res.data)
        }
      });
    },
    //当月能耗分析情况统计图标
    setchart6(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.equModel)
        arrY.push(Number(item.energy / 1000))
      })
      var option = {
        tooltip: {},
        grid: {
          top: 80,
          left: 80,
          right: 0,
          bottom: 40,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
              fontSize: 10,
            },
            // formatter:function(value){
            //     return value.split('').join('\n')
            // }
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "能耗：KWh",
          nameTextStyle: {
            color: "#BCCAF3",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            stack: "Total",
            barWidth: 10,
            showSymbol: false,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0], // 设置柱状图圆角
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: "#F2BE49"},
                    // { offset: 0.5, color: "#091b4d" },
                    {offset: 1, color: "#CB9419"},
                  ],
                  global: false,
                },
              },
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(13,183,116,0.38)",
                },
                {
                  offset: 1,
                  color: "rgb(13,183,116,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: arrY,
          },
        ],
      };
      echarts.init(document.getElementById("chart6")).setOption(option);
      // echarts.init(document.getElementById("chart6")).setOption({
      //   dataZoom: [
      //     {
      //       type: 'slider',    // 数据缩放类型为滑动条
      //       start: 0,          // 开始位置（范围为 0 到 100）
      //       end: 20            // 结束位置（范围为 0 到 100）
      //     },
      //     {
      //       type: 'inside',    // 内置的数据缩放类型，鼠标滚轮缩放
      //       start: 0,          // 开始位置（范围为 0 到 100）
      //       end: 100            // 结束位置（范围为 0 到 100）
      //     }
      //   ]
      // });
    },

    //用电环比分析
    getMonthOnMonth() {
      let param = {
        year: this.monthlyYear4
      }
      monitorApi.monthOnMonth(param).then((res) => {
        if (res.code === 200) {
          this.setchart4(res.data)
        }
      });
    },
    //用电环比分析图表
    setchart4(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY1 = []
      let arrY2 = []
      let arr3 = []
      data.forEach(item => {
        arrX.push(item.month)
        arrY1.push(item.energy)
        arrY2.push(item.ratio)
        const obj = {
          lastEnergy: item.lastEnergy,
          energy: item.energy,
          value: item.ratio
        }
        arr3.push(obj)
      })
      var option = {
        tooltip: {
          formatter: function (param) {
            let dom = `<div>当月: ${param.data.energy}</div><div>上月: ${param.data.lastEnergy}</div><div>环比: ${param.data.value}%</div>`
            return dom
          }
        },
        grid: {
          top: 80,
          left: 50,
          right: 60,
          bottom: 40,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
              fontSize: 10,
            },
            formatter: function (value) {
              return value + '月'
            }
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: "用电量：%",
          nameTextStyle: {
            color: "#BCCAF3",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "用电量",
            type: "line",
            // stack: "Total",
            showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            symbolSize: 6,
            itemStyle: {
              borderColor: "#0DB774", // 设置空心圆边框颜色
              color: "#030A24", // 可选，设置空心圆的填充颜色
            },
            lineStyle: {
              width: 1,
              color: "#0DB774",
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(13,183,116,0.38)",
                },
                {
                  offset: 1,
                  color: "rgb(13,183,116,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: arr3,
          },
        ],
      };
      echarts.init(document.getElementById("chart4")).setOption(option);
    },
    //总用电量实时情况统计
    getSelectPowergListByDay() {
      const param = {
        day: this.selectTimeOne
      }
      monitorApi.selectPowergListByDay(param).then((res) => {
        if (res.code === 200) {
          this.setchart3(res.data)
        }
      });
    },
    //总用电量实时情况统计图表
    setchart3(data) {
      // 指定图表的配置项和数据
      let arrY = []
      data.forEach(item => {
        arrY.push(Number(item.energy / 1000).toFixed(2))
      })
      var option = {
        tooltip: {},
        legend: {
          show: true,
          type: "scroll",
          icon: "circle",
          right: 60,
          top: 20,
          data: ["用电量"],
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
            color: "#BCCAF3",
          },
        },
        grid: {
          top: 70,
          left: 50,
          right: 60,
        },
        xAxis: {
          data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "用电量：kWh",
          nameTextStyle: {
            color: "#BCCAF3",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "用电量",
            type: "line",
            stack: "Total",
            showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            lineStyle: {
              color: "#43A8EF", // 可选，设置线条颜色
              width: 1,
            },
            itemStyle: {
              borderColor: "#43A8EF", // 设置空心圆边框颜色
              color: "#43A8EF", // 可选，设置空心圆的填充颜色
            },
            symbolSize: 6, // 设置空心圆的大小
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(31, 74, 137)",
                },
                {
                  offset: 1,
                  color: "rgb(6, 11, 31)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: arrY,
          },
        ],
      };
      echarts.init(document.getElementById("chart3")).setOption(option);
    },
    timeChangeOne() {
      this.getSelectPowergListByDay()
    },

    //发电环比分析
    getSelectPowergChainByYear() {
      const param = {
        year: this.selectTimeTwo
      }
      monitorApi.selectPowergChainByYear(param).then((res) => {
        if (res.code === 200) {
          let arr = res.data
          arr.forEach(item => {
            this.$set(item, 'recordDate', moment(item.recordDate).month() + 1)
          })
          this.setchart2(arr)
        }
      });
    },
    setchart2(data) {
      let arrX = []
      let arrY3 = []
      data.forEach(item => {
        arrX.push(item.recordDate)
        const obj = {
          value: Number(item.pvTotalEnergy).toFixed(2),
          nowPv: Number(item.nowPv / 1000).toFixed(2),
          lastPV: Number(item.lastPV / 1000).toFixed(2),
        }
        arrY3.push(obj)
      })
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          formatter: function (param) {
            let dom = `<div>当月: ${param.data.nowPv}</div><div>上月: ${param.data.lastPV}</div><div>环比: ${param.data.value}%</div>`
            return dom
          }
        },
        grid: {
          top: 80,
          left: 50,
          right: 60,
          bottom: 40,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
              fontSize: 10,
            },
            formatter: function (value) {
              return value + '月'
            }
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },

        },
        yAxis: {
          name: "发电量：%",
          nameTextStyle: {
            color: "#BCCAF3",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "环比",
            type: "line",
            // stack: "Total",
            showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            symbolSize: 6,
            itemStyle: {
              borderColor: "#0DB774", // 设置空心圆边框颜色
              color: "#030A24", // 可选，设置空心圆的填充颜色
            },
            lineStyle: {
              width: 1,
              color: "#0DB774",
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(13,183,116,0.38)",
                },
                {
                  offset: 1,
                  color: "rgb(13,183,116,0)",
                },
              ]),
            },
            // emphasis: {
            //   focus: "series",
            // },
            data: arrY3,
          },
        ],
      };
      echarts.init(document.getElementById("chart2")).setOption(option);
    },
    timeChangeTwo() {
      this.getSelectPowergChainByYear()
    },

    //电量实时情况统计
    getGetrealTimePowerStatistics() {
      const param = {
        dateTime: this.selectTimeThree
      }
      monitorApi.getrealTimePowerStatistics(param).then((res) => {
        if (res.code === 200) {
          this.setchart1(res.data)
        }
      });
    },
    //电量实时情况统计图表
    setchart1(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY1 = []
      let arrY2 = []
      let arrY3 = []
      data.forEach(item => {
        arrX.push(item.hour)
        arrY1.push(Number(item.esBatteryLevel / 1000).toFixed(2))
        arrY2.push(Number(item.psBatteryLevel / 1000).toFixed(2))
        arrY3.push(Number(item.ssBatteryLevel / 1000).toFixed(2))
      })
      var option = {
        tooltip: {},
        legend: {
          show: true,
          type: "scroll",
          icon: "circle",
          right: 60,
          top: 20,
          data: ['储能站电量','发电站电量','变电站电量'],
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
            color: "#BCCAF3",
          },
        },
        grid: {
          top: 70,
          left: 50,
          right: 60,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "电量：kWh",
          nameTextStyle: {
            color: "#BCCAF3",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "储能站电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#4683FF"},
                {offset: 1, color: "#2D54A4"},
              ]),
            },
            data: arrY1,
          },
          {
            name: "发电站电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#3DE2BC"},
                {offset: 1, color: "#3DE2BC"},
              ]),
            },
            data: arrY2,
          },
          {
            name: "变电站电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0], // 设置柱状图圆角
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: "#CEA500"},
                    // { offset: 0.5, color: "#091b4d" },
                    {offset: 1, color: "#EDC42F"},
                  ],
                  global: false,
                },
              },
            },
            data: arrY3,
          },
        ],
      };
      echarts.init(document.getElementById("chart1")).setOption(option);
    },
    timeChangeThree(){
      this.getGetrealTimePowerStatistics()
    },
    //当月累计节碳/碳排情况统计
    getGetCarbonEmissionStatistics() {
      monitorApi.getCarbonEmissionStatistics().then((res) => {
        if (res.code === 200) {
          this.setchart5(res.data)
        }
      });
    },
    setchart5(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY1 = []
      let arrY2 = []
      data.forEach(item => {
        arrX.push(item.hour)
        arrY1.push(item.psCarbon)
        arrY2.push(item.ssCarbon)
      })
      var option = {
        tooltip: {},
        legend: {
          show: true,
          type: "scroll",
          icon: "circle",
          right: 60,
          top: 20,
          data: ["发电站排放量", "配电站排放量"],
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
            color: "#BCCAF3",
          },
        },
        grid: {
          top: 70,
          left: 50,
          right: 60,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "单位: 碳排量",
          nameTextStyle: {
            color: "#BCCAF3",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#BCCAF3", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "发电站排放量",
            type: "line",
            // stack: "Total",
            // showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            lineStyle: {
              color: "#b68e3b", // 可选，设置线条颜色
              width: 1,
            },
            itemStyle: {
              borderColor: "#cb9d35", // 设置空心圆边框颜色
              color: "#cb9d35", // 可选，设置空心圆的填充颜色
            },
            symbolSize: 6, // 设置空心圆的大小
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#362f27",
                },
                {
                  offset: 1,
                  color: "#030c2a",
                },
              ]),
            },
            // emphasis: {
            //   focus: "series",
            // },
            data: arrY1,
          },
          {
            name: "配电站排放量",
            type: "line",
            // stack: "Total",
            // showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            itemStyle: {
              borderColor: "#0DB774", // 设置空心圆边框颜色
              color: "#0DB774", // 可选，设置空心圆的填充颜色
            },
            lineStyle: {
              width: 1,
              color: "#0DB774",
            },
            symbolSize: 6, // 设置空心圆的大小
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(13,183,116,0.38)",
                },
                {
                  offset: 1,
                  color: "rgb(13,183,116,0)",
                },
              ]),
            },
            // emphasis: {
            //   focus: "series",
            // },
            data: arrY2,
          },
        ],
      };
      echarts.init(document.getElementById("chart5")).setOption(option);
    },




    init() {
      // this.queryMonthlyElectricity();//
      // this.queryProductionEnergy();
      this.queryLastAnalysis();//
      // this.queryMonthlyLast();
      // this.queryTotalElectricity();
      this.queryTotalElectricity1();
      this.queryElectricity();
      this.queryElectricity1();
      this.queryCarbonEmission();
    },
    //跳转到第一屏幕
    jump() {
      this.$router.push("/monitoringLeft");
    },
    //3. 当月累计碳排情况统计
    carbonChange() {
      this.queryCarbonEmission();
    },
    //4. 当月能耗分析情况统计
    capacityChange() {
      this.type = "";
      this.queryEnergyConsumption();
    },
    typeChange() {
      this.queryEnergyConsumption();
    },
    // 5.用电量同比分析
    yearChange() {
      this.monthlyMonth = "";
      this.monthlyDay = "";
      this.queryTotalElectricity1();
    },
    monthChange() {
      this.monthlyYear = "";
      this.monthlyDay = "";
      this.queryTotalElectricity1();
    },
    dayChange() {
      this.monthlyYear = "";
      this.monthlyMonth = "";
      this.queryTotalElectricity1();
    },
    dayChange2() {
      this.monthlyYear2 = "";
      this.monthlyMonth2 = "";
      this.queryElectricity();
    },
    yearChange2() {
      this.monthlyMonth2 = "";
      this.monthlyDay2 = "";
      this.queryElectricity();
    },
    monthChange2() {
      this.monthlyYear2 = "";
      this.monthlyDay2 = "";
      this.queryElectricity();
    },
    dayChange3() {
      this.monthlyYear3 = "";
      this.monthlyMonth3 = "";
      this.queryTotalElectricity();
    },
    yearChange3() {
      this.monthlyMonth3 = "";
      this.monthlyDay3 = "";
      this.queryTotalElectricity();
    },
    monthChange3() {
      this.monthlyYear3 = "";
      this.monthlyDay3 = "";
      this.queryTotalElectricity();
    },
    dayChange4() {
      this.monthlyYear4 = "";
      this.monthlyMonth4 = "";
      this.queryElectricity1();
    },
    yearChange4() {
      this.monthlyMonth4 = "";
      this.monthlyDay4 = "";
      // this.queryElectricity1();
      this.getMonthOnMonth()
    },
    monthChange4() {
      this.monthlyYear4 = "";
      this.monthlyDay4 = "";
      this.queryElectricity1();
    },

    //6. 当月用电量情况统计
    regionChange() {
      // this.queryMonthlyElectricity();
    },
    // 7. 月用电量同比分析
    electricityChange() {
      // this.queryMonthlyLast();
    },
    //8. 生产用能前十排名
    produceChange() {
      // this.queryProductionEnergy();
    },
    // 额定容量
    queryCapacity() {
      monitorApi.queryCapacity().then((res) => {
        if (res.code === 200) {
          this.capacityOptions = res.data;
          // this.capacity = res.data[0].grossRatedCapacity;
          this.queryEnergyConsumption(this.capacity);
        }
      });
    },
    // 设备类型
    queryType() {
      monitorApi.queryType().then((res) => {
        if (res.code === 200) {

          this.typeOptions = res.data;
          if (res.data.length > 0) {
            this.type = res.data[0].transformerModel
            this.queryEnergyConsumption();
          }

        }
      });
    },
    // 截取对象属性
    intercept(objct, start, end) {
      /* 利用entries 将对象转换成二维数组*/
      /* 利用slice 进行截取*/
      let Index_0 = Object.entries(objct).slice(start, end);

      /* 利用fromEntries 将二维数组再转换为对象*/
      let Index_1 = Object.fromEntries(Index_0);

      /*返回对象*/
      return Index_1;
    },
    // 1.电量实时情况统计
    queryTotalElectricity1() {
      monitorApi.queryTotalElectricity().then((res) => {
        let object = res[0];
        let xAxisData = [];
        let seriesData = [];
        for (const key in object) {
          xAxisData.push(key);
          if (object[key]) {
            seriesData.push(object[key]);
          } else {
            seriesData.push(`0`);
          }
        }
      });
    },
    // 3.总用电量实时情况统计
    queryTotalElectricity() {
      monitorApi.queryTotalElectricity().then((res) => {
        let object = res[0];
        let xAxisData = [];
        let seriesData = [];
        for (const key in object) {
          xAxisData.push(key);
          if (object[key]) {
            seriesData.push(object[key]);
          } else {
            seriesData.push(`0`);
          }
        }
      });
    },
    // 2.发用电量统计
    queryElectricity() {
      monitorApi.queryElectricity().then((res) => {
        let object = res[0];
        let xAxisData = [];
        let seriesData = [];
        for (const key in object) {
          xAxisData.push(key);
          if (object[key]) {
            seriesData.push(object[key]);
          } else {
            seriesData.push(`0`);
          }
        }
      });
    },
    // 4.用用电量统计
    queryElectricity1() {
      monitorApi.queryElectricity().then((res) => {
        let object = res[0];
        let xAxisData = [];
        let seriesData = [];
        for (const key in object) {
          xAxisData.push(key);
          if (object[key]) {
            seriesData.push(object[key]);
          } else {
            seriesData.push(`0`);
          }
        }
      });
    },
    getMonthStartAndEnd(el) {
      let date;
      if (el) {
        date = new Date(el);
      } else {
        date = new Date();
      }
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const firstDay = new Date(year, month - 1, 2);
      const lastDay = new Date(year, month, 1);

      const formatDate = (d) => {
        return d.toISOString().slice(0, 10);
      };
      this.staTime = formatDate(firstDay) + ' 00:00:00';
      this.endTime = formatDate(lastDay) + ' 23:59:59';

    },
    //5. 当月累计碳排情况统计
    queryCarbonEmission() {
      // this.getMonthStartAndEnd()
      // let params={ 
      //   type: this.carbonRegion,
      //   monthStart: this.staTime,
      //   monthEnd: this.endTime,

      //  }
      monitorApi.queryTotalElectricity().then((res) => {
        let object = res[0];
        let xAxisData = [];
        let seriesData = [];
        for (const key in object) {
          xAxisData.push(key);
          if (object[key]) {
            seriesData.push(object[key]);
          } else {
            seriesData.push(`0`);
          }
        }
      });
    },

    // 6当月能耗分析情况统计
    queryEnergyConsumption(capacity) {
      let params = {
        transformerModel: this.type,
        monthStart: '',
        monthEnd: '',
      }
      let currentDate = new Date();
      // 获取当月第一天
      let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      // 获取当月最后一天，下个月第一天的前一天
      let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      params.monthStart = this.formatDate(firstDayOfMonth) + ' ' + '00:00:00'
      params.monthEnd = this.formatDate(lastDayOfMonth) + ' ' + '23:59:59'

      monitorApi.queryEnergyConsumption(params).then((res) => {
        // let object = res[0];
        // let xAxisData = res.data.map(item => {
        //   if (item.nowTime && typeof item.nowTime === 'string' && item.nowTime.length >= 10) {
        //     return item.nowTime.substring(0, 10);
        //   } else {
        //     return ''; // 或者您可以根据实际情况返回其他默认值
        //   }
        // });
        // let seriesData = res.data.map(item => item.onceEpaddIns);
        // for (const key in object) {
        //   xAxisData.push(key);
        //   if (object[key]) {
        //     seriesData.push(object[key]);
        //   } else {
        //     seriesData.push(`0`);
        //   }
        // }

      });
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从 0 开始计数，需要 +1
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    // 5.用电量同比分析
    queryLastAnalysis() {
      let params = {
        dayStart: '',
        dayEnd: '',
        monthStart: '',
        monthEnd: '',
        yearStart: '',
        yearEnd: '',
      };
      if (this.monthlyYear) {
        // params.year = this.monthlyYear;
        // this.getMonthStartAndEnd(this.monthlyYear)
        params.yearStart = this.formatDate(new Date(this.monthlyYear, 0, 1)) + ' 00:00:00'
        params.yearEnd = this.formatDate(new Date(this.monthlyYear, 11, 31)) + ' 23:59:59'
      }
      if (this.monthlyMonth) {
        this.getMonthStartAndEnd(this.monthlyMonth)
        params.monthStart = this.staTime
        params.monthEnd = this.endTime
      }
      if (this.monthlyDay) {
        params.dayStart = this.monthlyDay + ' 00:00:00'
        params.dayEnd = this.monthlyDay + ' 23:59:59'
      }
      monitorApi.yearMonthDay(params).then((res) => {
        let object = res.lastYear;
        let object2 = res.thisYear;
        let thisSeriesData = [];
        let lastSeriesData = [];
        let xAxisData = [];
        object.forEach(item => {
          if (this.monthlyYear) {
            lastSeriesData.push(item.yesterday)
          }
          if (this.monthlyMonth) {
            xAxisData.push(item.nowTime.substring(0, 7))
            lastSeriesData.push(item.yesterday)
          }
          if (this.monthlyDay) {
            xAxisData.push(item.nowTime.substring(0, 16))
            lastSeriesData.push(item.yesterday)
          }
        })
        object2.forEach(item => {
          if (this.monthlyYear) {
            xAxisData.push(item.nowTime.substring(0, 10))
          }
          thisSeriesData.push(item.yesterday)
        })
        // object.forEach(element=>{
        //   for (const key in element) {
        //       // xAxisData.push(key);
        //       // console.log(key);
        //       if (element[key]) {
        //         thisSeriesData.push(element[key]);
        //       } else {
        //         thisSeriesData.push(`0`);
        //       }
        //     }
        // })
      });
    },
    // //6. 当月用电量情况统计 能耗分析情况统计
    // queryMonthlyElectricity() {
    //   this.getMonthStartAndEnd()
    //   let params={ 
    //     type: this.electricityRegion,
    //     monthStart: this.staTime,
    //     monthEnd: this.endTime,

    //    }
    //   monitorApi
    //     .queryMonthlyElectricity(params)
    //     .then((res) => {
    //       let xAxisData = [];
    //       let seriesData = [];
    //       res.forEach((element) => {
    //         for (const key in element) {
    //           xAxisData.push(key);
    //           if (element[key]) {
    //             seriesData.push(element[key]);
    //           } else {
    //             seriesData.push(`0`);
    //           }
    //         }
    //       });

    //
    //     });
    // },
    // 7. 月用电量同比分析
    // queryMonthlyLast() {
    //   this.getMonthStartAndEnd(this.electricityMonth)
    //   let params = {
    //     // flage: this.powerRegion,
    //     type: this.powerRegion,
    //     monthStart: this.staTime,
    //     monthEnd: this.endTime,
    //   };
    //   // if (this.electricityMonth) {
    //   //   let month = this.electricityMonth.slice(-2);
    //   //   params.month = Number(month);
    //   //   let year = this.electricityMonth.slice(0, 4);
    //   //   params.year = Number(year);
    //   // }
    //   monitorApi.queryMonthlyAnalysis(params).then((res) => {
    //     let object = res.lastYear;
    //     let object2 = res.thisYear;
    //     let thisSeriesData = []; 
    //     let lastSeriesData = []; 
    //     let xAxisData = []; 
    //     object.forEach(element=>{
    //       for (const key in element) {
    //           // xAxisData.push(key);
    //           // console.log(key);
    //           if (element[key]) {
    //             thisSeriesData.push(element[key]);
    //           } else {
    //             thisSeriesData.push(`0`);
    //           }
    //         }
    //     })
    //     object2.forEach(element=>{
    //       for (const key in element) {
    //           xAxisData.push(key);
    //           if (element[key]) {
    //             lastSeriesData.push(element[key]);
    //           } else {
    //             lastSeriesData.push(`0`);
    //           }
    //         }
    //     })
    //     // console.log('~~~速',xAxisData);
    //     // console.log('~~~速',lastSeriesData);


    //     this.setchart7(xAxisData, lastSeriesData, thisSeriesData);
    //   });
    // },
    //8. 生产用能前十排名
    // queryProductionEnergy() {
    //   this.getMonthStartAndEnd(this.produceMonth)
    //   let params = {
    //     // flage: ,
    //     type: this.produceRegion,
    //     monthStart: this.staTime,
    //     monthEnd: this.endTime,
    //   };
    //   // if (this.produceMonth) {
    //   //   let month = this.produceMonth.slice(-2);
    //   //   params.month = Number(month);
    //   //   let year = this.produceMonth.slice(0, 4);
    //   //   params.year = Number(year);
    //   // }
    //   monitorApi.queryProductionEnergy(params).then((res) => {
    //     let xAxisData = [];
    //     let seriesData = [];
    //     let object = res;

    //     object.forEach((element,index)=>{
    //       for (const key in element) {
    //         if(index <10){

    //           xAxisData.push(key);
    //           if (element[key]) {
    //             seriesData.push(element[key]);
    //           } else {
    //             seriesData.push(`0`);
    //           }
    //         }
    //         }
    //     })

    //     this.setchart8(xAxisData, seriesData);
    //   });
    // },
  },
};
</script>
<style scoped>
.tabNavBox {
  z-index: 1;
  position: absolute;
  top: 0.14rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.1rem;
  padding-left: 2.5rem;
}

.tabNavBox .nav {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 0.12rem;
  flex: 10%;
  max-width: 10%;
  text-align: center;
  color: #AED6FF;
  background-color: rgba(14, 100, 217, 0.11);
}

.tabNavBox .navClick {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 0.12rem;
  flex: 10%;
  max-width: 10%;
  text-align: center;
  color: #ffc74a;
  background-color: rgba(14, 100, 217, 0.11);
}

.left_nav {
  width: 30px;
  height: 30px;
  background: url("../../assets/images/monitoring/leftNav.png") no-repeat;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 5.5rem;
  cursor: pointer;
}

.row-style {
  height: 2.83rem;
  margin-bottom: 0.2rem;
  margin-left: 0.24rem;
}

/* 修改 el-select 样式  */
.el-select {
  z-index: 1990;
  position: absolute;
  right: 50px;
  top: 10px;
  width: 0.8rem;
  border: 1px solid #0e447e;
}

.el-select :deep() .el-select-dropdown {
  background: #041c4c !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

.el-select :deep() .el-select-dropdown__item.hover {
  background: #041c4c !important;
  color: #fff;
}

.el-select :deep() .el-select-dropdown__item {
  color: #aed6ff;
}

.el-select :deep() .el-select-dropdown__item.selected {
  color: #fff;
}

.el-select :deep() .el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041c4c;
}

.el-date-editor :deep() .el-input__inner {
  width: 100% !important;
}

.el-select :deep() .el-input__inner {
  width: 100% !important;
}

:deep() .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.25rem;
  width: 0.8rem;
  border: none;
  font-weight: 400;
  font-size: 12px;
  color: #d9e3ff;
}

:deep() .el-input__icon {
  height: 40px;
  position: relative;
  top: -7px;
}

.z-index {
  z-index: 2024;
}

.el-date-editor {
  z-index: 1990;
  position: absolute;
  right: 50px;
  top: 10px;
  width: 0.7rem;
  border: 1px solid #0e447e;
}

/* 修改日期选择器样式 */
::v-deep .el-icon-date {
  color: #aed6ff;
}

/* .el-select{
        position: absolute;
        right: 50px;
        top:10px;
        width: 0.8rem;
        border:1px solid #0E447E;
    } */
#monitoringRight {
  width: 1920px;
  height: 1080px;
  background: url("../../assets/images/monitoring/rightbg.jpg") no-repeat;
  background-size: 100vw;
}

.right_header {
  width: 100%;
  height: 1.08rem;
  background: url("../../assets/images/monitoring/rightHeader.png") no-repeat;
  background-size: 100vw;
}

.right_div1, .right_div3, .right_div5 {
  float: left;
  position: relative;
  box-sizing: border-box;
  padding-top: 0.2rem;
  margin-right: 0.18rem;
  width: 1239px;
  height: 2.83rem;

}

.right_div1 {
  background: url("../../assets/images/monitoring/dlssqktj.png") no-repeat;
}

.right_div3 {
  background: url("../../assets/images/monitoring/zydlssqktj.png") no-repeat;
}

.right_div5 {
  background: url("../../assets/images/monitoring/dyljtpqktj.png") no-repeat;
}

.right_div2 {
  background: url("../../assets/images/monitoring/fdhbfx.png") no-repeat;
}

.right_div4 {
  background: url("../../assets/images/monitoring/ydhbfx.png") no-repeat;
}

.right_div6 {
  background: url("../../assets/images/monitoring/dynhfxqktj.png") no-repeat;
}

.right_div1 #chart1, .right_div3 #chart3, .right_div5 #chart5 {
  width: 1239px;
  height: 297px;
}

.right_div2, .right_div4 {
  position: relative;
  width: 6.09rem;
  height: 2.83rem;
  float: left;
  /* background: url("../../assets/images/monitoring/ydltj.png") no-repeat; */
}

.right_div2 #chart2, .right_div4 #chart4 {
  width: 6.09rem;
  height: 2.83rem;
}

.right_div6 {
  width: 6.09rem;
  height: 2.83rem;
  float: left;
  position: relative;
  /* background: url("../../assets/images/monitoring/scynqspm.png") no-repeat; */
}

.right_div6 #chart6 {
  width: 6.09rem;
  height: 2.83rem;
}

.left_main {
  width: 5.71rem;
  height: 9.36rem;
  background: url("../../assets/images/monitoring/leftMain.png") no-repeat;
  float: left;
}

.center_main {
  float: left;
  width: 7.5rem;
  height: 7rem;
}

.right_main {
  width: 5.71rem;
  height: 9.36rem;
  background: url("../../assets/images/monitoring/rightMain.png") no-repeat;
  float: right;
}

.monitoringFooter {
  height: 1.38rem;
  margin-top: -1rem;
  background: url("../../assets/images/monitoring/rightFooter.png") no-repeat;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.header_left {
  font-size: 12px;
  color: #bccaf3;
  position: absolute;
  left: 0.5rem;
  top: 0.4rem;
}

.header_right {
  font-size: 12px;
  color: #bccaf3;
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}

.header_left img,
.header_right img {
  vertical-align: bottom;
  margin-right: 0.1rem;
}

.header_left span,
.header_right span {
  cursor: pointer;
  margin-right: 0.1rem;
}

.main_one {
  width: 5.71rem;
  height: 4.58rem;
  margin-bottom: 0.17rem;
  overflow: hidden;
}

.one_div {
  padding-top: 0.73rem;
  padding-left: 0.14rem;
  height: 0.61rem;
  margin-bottom: 0.13rem;
}

.main_one .two_div {
  height: 0.61rem;
  padding-left: 0.14rem;
  margin-bottom: 0.14rem;
}

.main_one .div_main {
  float: left;
  margin-right: 0.25rem;
  width: 1.81rem;
  height: 0.61rem;
  padding-left: 0.75rem;
  padding-top: 0.13rem;
  box-sizing: border-box;
}

.main_two .div_main {
  font-size: 0.12rem;
  color: #bccaf3;
}

.main_two .two_div {
  height: 0.32rem;
  width: 3.54rem;
  /* background-color: rgba(12,62,146,.11); */
  padding: 0.06rem 0.25rem 0.13rem 0.22rem;
  box-sizing: border-box;
  margin-bottom: 0.1rem;
}

.three_div {
  height: 0.41rem;
  padding-left: 0.14rem;
  margin-bottom: 0.1rem;
}

.four_div {
  height: 0.35rem;
  padding-left: 0.14rem;
  margin-bottom: 0.2rem;
}

.three_main {
  float: left;
  margin-right: 0.24rem;
}

.four_main {
  float: left;
  margin-right: 0.25rem;
}

.three_main img,
.four_main img {
  float: left;
  margin-right: 0.1rem;
}

.three_main span {
  font-size: 0.12rem;
  color: #d9e3ff;
}

.main_two {
  height: 4.63rem;
  padding-left: 0.14rem;
  padding-top: 0.69rem;
  overflow: hidden;
  box-sizing: border-box;
}

#two_footer {
  height: 2.04rem;
  width: 5.52rem;
  background: url("../../assets/images/monitoring/xwzxbg.png") no-repeat;
}

#one_echarts {
  width: 5.71rem;
  height: 2.5rem;
}

#two_echarts {
  width: 5.71rem;
  height: 2.04rem;
  margin-left: 1.6rem;
  background: url("../../assets/images/monitoring/pdxsbg.png") no-repeat;
}

#three_echarts {
  width: 5.71rem;
  height: 1.7rem;
  margin-left: 1.8rem;
  background: url("../../assets/images/monitoring/byqnxbg.png") no-repeat;
}

#four_echarts {
  width: 5.71rem;
  height: 2rem;
}

.one_title {
  width: 5.71rem;
  margin-left: 1.6rem;
  padding-left: 0.42rem;
  padding-top: 0.08rem;
  font-size: 14px;
  color: #d9e3ff;
  height: 0.32rem;
  background: url("../../assets/images/monitoring/pdxsqktj.png") no-repeat;
  box-sizing: border-box;
}
</style>
<style lang="css">
/*  */
.echarts-picker {
  background: #041c4c;
  color: #aed6ff;
  border-color: #041c4c;
}

.echarts-picker .el-picker-panel__body .el-date-picker__header-label {
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__icon-btn {
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-year-table .cell {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-year-table .today .cell {
  background: #041c4c;
  color: #409eff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-month-table .cell {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-month-table .today .cell {
  background: #041c4c;
  color: #409eff;
}

.echarts-picker .el-picker-panel__body .el-date-table .disabled div {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker[x-placement^="bottom"] .popper__arrow::after {
  top: 0 !important;
  border-bottom-color: #041c4c !important;
}

.echarts-picker[x-placement^="top"] .popper__arrow::after {
  bottom: 0 !important;
  border-top-color: #041c4c !important;
}
</style>
