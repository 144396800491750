
/**
 * echarts X轴时间
 */
  let xDate = {
    xDate96: [
        '00',
        '00:15',
        '00:30',
        '00:45',
        '01',
        '01:15',
        '01:30',
        '01:45',
        '02',
        '02:15',
        '02:30',
        '02:45',
        '03',
        '03:15',
        '03:30',
        '03:45',
        '04',
        '04:15',
        '04:30',
        '04:45',
        '05',
        '05:15',
        '05:30',
        '05:45',
        '06',
        '06:15',
        '06:30',
        '06:45',
        '07',
        '07:15',
        '07:30',
        '07:45',
        '08',
        '08:15',
        '08:30',
        '08:45',
        '09',
        '09:15',
        '09:30',
        '09:45',
        '10',
        '10:15',
        '10:30',
        '10:45',
        '11',
        '11:15',
        '11:30',
        '11:45',
        '12',
        '12:15',
        '12:30',
        '12:45',
        '13',
        '13:15',
        '13:30',
        '13:45',
        '14',
        '14:15',
        '14:30',
        '14:45',
        '15',
        '15:15',
        '15:30',
        '15:45',
        '16',
        '16:15',
        '16:30',
        '16:45',
        "17",
        "17:15",
        "17:30",
        "17:45",
        "18",
        "18:15",
        "18:30",
        "18:45",
        "19",
        "19:15",
        "19:30",
        "19:45",
        "20",
        "20:15",
        "20:30",
        "20:45",
        "21",
        "21:15",
        "21:30",
        "21:45",
        "22",
        "22:15",
        "22:30",
        "22:45",
        "23",
        "23:15",
        "23:30",
        "23:45",
        "24"
      ],
      xDate963: [
        '0',
        '00:15',
        '00:30',
        '00:45',
        '1',
        '01:15',
        '01:30',
        '01:45',
        '2',
        '02:15',
        '02:30',
        '02:45',
        '3',
        '03:15',
        '03:30',
        '03:45',
        '4',
        '04:15',
        '04:30',
        '04:45',
        '5',
        '05:15',
        '05:30',
        '05:45',
        '6',
        '06:15',
        '06:30',
        '06:45',
        '7',
        '07:15',
        '07:30',
        '07:45',
        '8',
        '08:15',
        '08:30',
        '08:45',
        '9',
        '09:15',
        '09:30',
        '09:45',
        '10',
        '10:15',
        '10:30',
        '10:45',
        '11',
        '11:15',
        '11:30',
        '11:45',
        '12',
        '12:15',
        '12:30',
        '12:45',
        '13',
        '13:15',
        '13:30',
        '13:45',
        '14',
        '14:15',
        '14:30',
        '14:45',
        '15',
        '15:15',
        '15:30',
        '15:45',
        '16',
        '16:15',
        '16:30',
        '16:45',
        "17",
        "17:15",
        "17:30",
        "17:45",
        "18",
        "18:15",
        "18:30",
        "18:45",
        "19",
        "19:15",
        "19:30",
        "19:45",
        "20",
        "20:15",
        "20:30",
        "20:45",
        "21",
        "21:15",
        "21:30",
        "21:45",
        "22",
        "22:15",
        "22:30",
        "22:45",
        "23",
        "23:15",
        "23:30",
        "23:45",
        "24"
      ],
      xDate962: [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
        "18:15",
        "18:30",
        "18:45",
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
        "22:00",
        "22:15",
        "22:30",
        "22:45",
        "23:00",
        "23:15",
        "23:30",
        "23:45"
      ],
      xDate24: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ],
      xDate24t: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
      ],
      xDate2400: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
      xDateName: [
        '济南',
        '青岛',
        '淄博',
        '枣庄',
        '东营',
        '烟台',
        '潍坊',
        '济宁',
        '泰安',
        '威海',
        '日照',
        '莱芜',
        '临沂',
        '德州',
        '聊城',
        '滨州',
        '菏泽',
      ]
  }
    let date = new Date();
    let date2 = new Date(date);
    let date3 = new Date(date);
    date2.setDate(date2.getDate() - 1)
    date3.setDate(date3.getDate() - 365)
    let nowTime = ''
    // let nowTime = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") 
    let yesTime = date2.getFullYear() + "-" + (date2.getMonth() + 1).toString().padStart(2, "0") + "-" + date2.getDate().toString().padStart(2, "0")
    let lastYearTime = date3.getFullYear() + "-" + (date3.getMonth() + 1).toString().padStart(2, "0") + "-" + date3.getDate().toString().padStart(2, "0")


  export default {
    xDate,
    nowTime,
    yesTime,
    lastYearTime,
    // userBeasInfo
    
  }
  