import {getRequest, postRequest, monitorGateway,urlTitle} from "@/utils/request";
import qs from "qs";

window.qs = qs;
// 综合监控 electricity
export const monitorApi = {
    // 容量
    busDrivingSchool: () => {
        return postRequest(`/power-system/system/thirdPartyAcquisition/url`);
    },
    // 容量
    queryCapacity: () => {
        return getRequest(`${monitorGateway}/monitors/gc`);
    },
    // 类型
    queryType: () => {
        return getRequest(`${monitorGateway}/monitors/type`);
    },
    // 总用电量实时情况统计
    queryTotalElectricity: () => {
        return getRequest(`${monitorGateway}/monitors/hour/power`);
    },
    // 用电量统计
    queryElectricity: () => {
        return getRequest(`${monitorGateway}/monitors/month/power`);
    },
    // 当月累计碳排情况统计
    queryCarbonEmission: (params) => {
        return getRequest(`${monitorGateway}/monitors/month/co2`, params);
    },
    // 当月能耗分析情况统计
    queryEnergyConsumption: (params) => {
        return getRequest(`${monitorGateway}/monitors/month/equipment`, params);
    },
    queryMonthlyAnalysis: (params) => {
        return getRequest(`${monitorGateway}/monitor/monthly/common`, params);
    },
    // 当月用电量情况统计
    queryMonthlyElectricity: (params) => {
        return getRequest(`${monitorGateway}/monitor/monthly/statistics`, params);
    },
    // 当月用电量情况统计
    queryProductionEnergy: (params) => {
        return getRequest(`${monitorGateway}/monitor/ranking`, params);
    },
    // 碳排情况统计
    queryTotalCarbon: () => {
        return getRequest(`${monitorGateway}/monitors/hour/co2`);
    },
    // 碳排情况统计
    yearMonthDay: (params) => {
        return getRequest(`${monitorGateway}/monitor/year/month/day`, params);
    },
    //调取后端解析用户信息接口(前端无需解析处理)
    sso: (params) => {
        return getRequest(`/power-system/sso/initUserRole`, params);
    },
    //当月能耗分析情况统计
    thisMonth: (params) => {
        return getRequest(`${monitorGateway}/distributionStat/transformerStat/thisMonth`, params);
    },

    //用电环比分析
    monthOnMonth: (params) => {
        return getRequest(`${monitorGateway}/distributionStat/monthOnMonth`, params);
    },

    //总用电量实时情况统计
    selectPowergListByDay: (params) => {
        return getRequest(`${urlTitle}/synthesis/selectPowergListByDay`, params);
    },
    //发电环比分析
    selectPowergChainByYear: (params) => {
        return getRequest(`${urlTitle}/synthesis/selectPowergChainByYear`, params);
    },

    //电量实时情况统计
    getrealTimePowerStatistics: (params) => {
        return getRequest(`${urlTitle}/aggregation/module/getrealTimePowerStatistics`, params);
    },

    //当月累计节碳/碳排情况统计
    getCarbonEmissionStatistics: (params) => {
        return getRequest(`${urlTitle}/aggregation/module/getCarbonEmissionStatistics`, params);
    },

    // 首页源柱图
    getSource: (params) => {
        return getRequest(`${urlTitle}/synthesis/selectPowergIndex`, params);
    },
    // 首页网柱图
    getNetwork: (params) => {
        return getRequest(`${monitorGateway}/loadeqStatistic/Time/getStatisticHourly`, params);
    },

    // 首页荷柱图
    getLotus: (params) => {
        return getRequest(`${urlTitle}/loadEquipments/powerStat/today/hourly`, params);
    },
    // 首页储柱图
    getStore: (params) => {
        return getRequest(`${urlTitle}/synthesis/selectStorageIndex`, params);
    },
};
